import Link from 'next/link';

import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { PAGE_QUERY_FILTER_SLUGResult } from '@/_lib/types';

export type RelatedContentProps = Extract<
  NonNullable<NonNullable<PAGE_QUERY_FILTER_SLUGResult>['body']>[number],
  { _type: 'relatedContent' }
>;

export async function RelatedContent(props: RelatedContentProps) {
  const href = props.internal?.slug?.current ?? props.external;

  if (!href) {
    return null;
  }

  return (
    <aside
      data-testid="related-content"
      className="not-prose lg:float-right lg:mr-[-47.5%] lg:w-[47.5%] lg:pl-20"
    >
      <div className="flex list-none flex-col px-0 pt-0">
        <div className="mb-6 pt-4">
          <Link href={href} className="order-2 hover:text-black focus:text-black">
            <h2>{props.headline}</h2>
          </Link>
          <RichTextClient className="text-base" value={props.content} />
          <Link href={href} className="text-base uppercase">
            {props.linkText}
          </Link>
        </div>
      </div>
    </aside>
  );
}
