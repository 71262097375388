'use client';

import { useState } from 'react';

import { Form } from 'react-aria-components';

import { Button } from '@/_components/Global/Button/button';
import { FloatingField } from '@/_components/Global/FloatingField/field';
import { useActionState } from '@/_hooks/use-action-state';
import { NewsletterSignUp as NewsletterSignUpProps } from '@/_lib/types';
import { addNewsletterSubscriber } from '@/_services/add-newsletter-subscriber';

import { Turnstile } from '../Turnstile/turnstile';

export type NewsletterSignupState = 'Initial' | 'Invalid' | 'Successful' | 'Failure';

export default function NewsletterSignUp({
  heading,
  nonce,
}: NewsletterSignUpProps & { nonce: string }) {
  const [token, setToken] = useState<string | null>(null);

  const [, submitAction, isPending] = useActionState<NewsletterSignupState, FormData>(
    (state, payload) => {
      payload.append('cf-turnstile-response', token ?? '');

      return addNewsletterSubscriber(state, payload);
    },
    'Initial',
  );

  return (
    <section
      data-testid="newsletter-sign-up"
      className="flex flex-col gap-8 rounded bg-fuchsia-50 p-8"
    >
      <h2 className="m-0 text-5xl font-bold">{heading}</h2>

      <p className="text-xl">
        We send one or two newsletters per month with articles, case studies, and Habanero news and
        events.
      </p>

      <Form action={submitAction}>
        <Turnstile onSuccess={setToken} onError={() => setToken(null)} nonce={nonce} />

        <div className="flex flex-col gap-4 md:flex-row md:items-center">
          <FloatingField label="Name" name="name" type="text" isRequired />
          <FloatingField label="Email" name="email" type="email" isRequired />

          <Button type="submit" aria-disabled={isPending}>
            Submit
          </Button>
        </div>
      </Form>
    </section>
  );
}
