import { SanityImage } from '@/_components/Global/SanityImage/sanity-image';
import { PAGE_QUERY_FILTER_SLUGResult } from '@/_lib/types';

export type PersonBlockProps = Extract<
  NonNullable<NonNullable<PAGE_QUERY_FILTER_SLUGResult>['body']>[number],
  { _type: 'personBlock' }
>;

export async function PersonBlock({ person }: PersonBlockProps) {
  if (!person) return null;

  return (
    <div className="not-prose" data-testid="person-block">
      <div className="my-8 flex items-center gap-4">
        {person.rollupImage && (
          <div className="flex-shrink-0">
            <div className="relative h-[78px] w-[78px] overflow-hidden rounded-full">
              <SanityImage
                image={person.rollupImage}
                alt={person.title || ''}
                className="h-full w-full object-cover object-center"
                width={78}
              />
            </div>
          </div>
        )}
        <div>
          <p className="text-xl font-bold">{person.title}</p>
          <p className="text-lg text-slate-700">{person.jobTitle}</p>
        </div>
      </div>
    </div>
  );
}
