import React from 'react';

import Link from 'next/link';

import { formatDate } from '@/_lib/format-date';
import { formatDateRange } from '@/_lib/format-date-range';
import { Person } from '@/_lib/types';

import { SanityImage } from '../SanityImage/sanity-image';

type CardProps = {
  date?: string | null;
  endDate?: string | null;
  href: string;
  image: any;
  people?: Person[];
  startDate?: string | null;
  title: string;
};

export const Card = ({ date, href, image, people, startDate, endDate, title }: CardProps) => {
  return (
    <div
      data-testid="card"
      className={`group flex flex-col ${image ? '' : 'rounded border border-slate-200 p-4'}`}
    >
      <Link className="flex flex-col gap-2" href={href}>
        {image && (
          <div className="mb-1 overflow-hidden rounded-lg">
            <SanityImage
              alt=""
              className="w-full rounded-lg object-cover transition-transform duration-300 group-hover:scale-105"
              image={image}
              sizes="(max-width: 48rem) 100vw, (max-width: 60rem) 33vw, 300px"
              style={{ viewTransitionName: `slug-${href.replaceAll('/', '-')}` }}
              width={300}
            />
          </div>
        )}

        <span className="text-lg font-bold text-balance text-slate-900 hover:text-red-700">
          {title}
        </span>

        {people && (
          <div className="flex flex-wrap gap-3">
            {people.map((person) => (
              <span
                key={person._id}
                className="flex items-center gap-2 text-sm font-semibold text-slate-700"
              >
                <div className="h-6 w-6">
                  <SanityImage
                    alt=""
                    className="h-full w-full rounded-full object-cover"
                    image={person.rollupImage}
                    height={24}
                  />
                </div>

                {person.title}
              </span>
            ))}
          </div>
        )}

        {date && (
          <time className="text-sm text-slate-700" dateTime={date}>
            {formatDate(date)}
          </time>
        )}

        {startDate && (
          <time className="text-sm text-slate-700" dateTime={startDate}>
            {formatDateRange(startDate, endDate)}
          </time>
        )}
      </Link>
    </div>
  );
};
