'use client';

import { FormEvent, useState } from 'react';

import { Form } from 'react-aria-components';

import { Button } from '@/_components/Global/Button/button';
import { Field } from '@/_components/Global/Field/field';
import { useActionState } from '@/_hooks/use-action-state';
import { PAGE_QUERY_FILTER_SLUGResult } from '@/_lib/types';
import { sendDemoRequest } from '@/_services/send-demo-request';

import { Turnstile } from '../Turnstile/turnstile';

export type DemoFormState = 'Initial' | 'Invalid' | 'Successful' | 'Failure';

type DemoFormProps = Extract<
  NonNullable<NonNullable<PAGE_QUERY_FILTER_SLUGResult>['pageBuilder']>[number],
  { _type: 'demoForm' }
> & { nonce: string };

export default function DemoForm({
  heading,
  firstNameLabel,
  firstNameRequiredMessage,
  lastNameLabel,
  emailLabel,
  emailRequiredMessage,
  interestLabel,
  interestOptions,
  sendButton,
  successPage,
  nonce,
}: DemoFormProps) {
  const [token, setToken] = useState<string | null>(null);
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
  const [, submitAction, isPending] = useActionState<DemoFormState, FormData>((state, payload) => {
    payload.append('cf-turnstile-response', token ?? '');

    // Add all selected interests to the form data
    selectedInterests.forEach((interest) => {
      payload.append('interests', interest);
    });

    return sendDemoRequest(state, payload);
  }, 'Initial');

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitAction(new FormData(event.currentTarget));
  };

  const handleInterestChange = (option: string, checked: boolean) => {
    if (checked) {
      setSelectedInterests((prev) => [...prev, option]);
    } else {
      setSelectedInterests((prev) => prev.filter((item) => item !== option));
    }
  };

  return (
    <section data-testid="demo-form" className="max-w-[400]">
      {heading && <h2>{heading}</h2>}

      <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <input name="redirectUrl" type="hidden" value={successPage?.slug.current} />

        <div className="flex w-full gap-4">
          <Field
            autoComplete="given-name"
            name="firstName"
            type="text"
            isRequired
            className="w-full"
            label={firstNameLabel}
            errorMessage={firstNameRequiredMessage}
          />

          <Field
            autoComplete="family-name"
            name="lastName"
            type="text"
            className="w-full"
            label={lastNameLabel}
          />
        </div>

        <Field
          autoComplete="email"
          name="email"
          type="email"
          isRequired
          className="flex flex-col"
          label={emailLabel}
          errorMessage={emailRequiredMessage}
        />

        <div className="flex flex-col">
          <fieldset className="m-0 border-0 p-0">
            <legend className="mb-2">{interestLabel}</legend>
            <div className="flex flex-col gap-2">
              {interestOptions.map((option: string) => (
                <label key={option} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="interest-checkbox"
                    value={option}
                    checked={selectedInterests.includes(option)}
                    onChange={(e) => handleInterestChange(option, e.target.checked)}
                    className="h-4 w-4"
                  />
                  <span>{option}</span>
                </label>
              ))}
            </div>
          </fieldset>
        </div>

        <Turnstile onSuccess={setToken} onError={() => setToken(null)} nonce={nonce} />

        <Button
          type="submit"
          aria-disabled={isPending || !token || selectedInterests.length === 0}
          isDisabled={isPending || !token || selectedInterests.length === 0}
        >
          {sendButton}
        </Button>
      </Form>
    </section>
  );
}
