import { Suspense } from 'react';

import { GatedContent as Props } from '@/_lib/types';

import { GatedContentClient } from './gated-content.client';

export function GatedContent(props: Props) {
  return (
    <div data-testid="gated-content" className="not-prose">
      <div className="rounded border border-gray-200 p-4">
        <Suspense>
          <GatedContentClient {...props} />
        </Suspense>
      </div>
    </div>
  );
}
