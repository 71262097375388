'use client';

import { useEffect, useRef, useState } from 'react';

import { Background as Props } from '@/_lib/types';

export function Background(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = useState(false);

  // Map colors to their CSS custom properties and text colors
  const colorMap = {
    White: {
      text: 'text-black',
      cssVar: 'var(--color-white)',
    },
    Aqua: {
      text: 'text-white',
      cssVar: 'var(--color-aqua-900)',
    },
    Blue: {
      text: 'text-white',
      cssVar: 'var(--color-blue-900)',
    },
    Green: {
      text: 'text-white',
      cssVar: 'var(--color-green-900)',
    },
    Fuschia: {
      text: 'text-white',
      cssVar: 'var(--color-fuchsia-900)',
    },
    Tangerine: {
      text: 'text-white',
      cssVar: 'var(--color-orange-900)',
    },
  };

  const { text, cssVar } = colorMap[props.color] || colorMap['White'];

  useEffect(() => {
    if (!ref.current) return;

    const current = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);

            // Apply background color to the body with transition
            document.body.style.backgroundColor = cssVar;

            // Apply text color based on background
            if (props.color === 'White') {
              document.body.style.color = 'var(--color-black)';

              document.querySelector('.prose')?.classList.remove('prose-invert');
            } else {
              document.body.style.color = 'var(--color-white)';

              document.querySelector('.prose')?.classList.add('prose-invert');
            }
          }
        });
      },
      { threshold: 0.1 }, // Trigger when 10% of the element is visible
    );

    observer.observe(current);

    return () => {
      if (current) {
        observer.unobserve(current);
      }

      // Clean up styles when component unmounts
      document.body.style.backgroundColor = '';
      document.body.style.color = '';
    };
  }, [cssVar, props.color, isInView]);

  return (
    <div
      data-testid="background"
      ref={ref}
      className={`h-1 w-full ${text} transition-opacity duration-500 ${isInView ? 'opacity-100' : 'opacity-0'} flex items-center justify-center`}
    ></div>
  );
}
