import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { SanityImage } from '@/_components/Global/SanityImage/sanity-image';
import { Figure as Props } from '@/_lib/types';

export async function Figure({ content, image }: Props) {
  return (
    <div className="not-prose" data-testid="figure">
      <figure>
        <SanityImage alt="" className="h-auto w-full rounded-2xl" image={image} />

        {content && content.length > 0 && (
          <figcaption>
            <RichTextClient value={content} />
          </figcaption>
        )}
      </figure>
    </div>
  );
}
