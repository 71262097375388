'use client';

import { useCallback, useState } from 'react';

import Link from 'next/link';

import { Form } from 'react-aria-components';

import { Button } from '@/_components/Global/Button/button';
import { Field } from '@/_components/Global/Field/field';
import { useActionState } from '@/_hooks/use-action-state';
import { PAGE_QUERY_FILTER_SLUGResult } from '@/_lib/types';
import { sendContactEmail } from '@/_services/send-contact-email';

import { Turnstile } from '../Turnstile/turnstile';

export type ContactFormState = 'Initial' | 'Invalid' | 'Successful' | 'Failure';

type ContactFormProps = Extract<
  NonNullable<NonNullable<PAGE_QUERY_FILTER_SLUGResult>['pageBuilder']>[number],
  { _type: 'contactForm' }
> & { nonce: string };

const COPY_BUTTON_DELAY = 3000;

export default function ContactForm({
  heading,
  ourEmailLabel,
  contactEmail,
  copyButton,
  copiedButton,
  firstNameLabel,
  firstNameRequiredMessage,
  lastNameLabel,
  emailLabel,
  emailRequiredMessage,
  messageLabel,
  messageRequiredMessage,
  sendButton,
  successPage,
  nonce,
}: ContactFormProps) {
  const [copied, setCopied] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [, submitAction, isPending] = useActionState<ContactFormState, FormData>(
    (state, payload) => {
      payload.append('cf-turnstile-response', token ?? '');

      return sendContactEmail(state, payload);
    },
    'Initial',
  );

  return (
    <section data-testid="contact-form" className="max-w-[400]">
      {heading && <h2>{heading}</h2>}

      {contactEmail && (
        <div>
          <span>{ourEmailLabel}:</span>
          <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
          <button
            onClick={() => {
              navigator.clipboard.writeText(contactEmail);

              setCopied(true);
              setTimeout(() => setCopied(false), COPY_BUTTON_DELAY);
            }}
            type="button"
          >
            {copied ? copiedButton : copyButton}
          </button>
        </div>
      )}

      <Form className="flex flex-col gap-4" action={submitAction}>
        <input name="redirectUrl" type="hidden" value={successPage?.slug.current} />

        <div className="flex w-full gap-4">
          <Field
            autoComplete="given-name"
            name="firstName"
            type="text"
            isRequired
            className="w-full"
            label={firstNameLabel}
            errorMessage={firstNameRequiredMessage}
          />

          <Field
            autoComplete="family-name"
            name="lastName"
            type="text"
            className="w-full"
            label={lastNameLabel}
          />
        </div>

        <Field
          autoComplete="email"
          name="email"
          type="email"
          isRequired
          className="flex flex-col"
          label={emailLabel}
          errorMessage={emailRequiredMessage}
        />

        <Field
          name="message"
          type="textarea"
          isRequired
          className="flex flex-col"
          label={messageLabel}
          errorMessage={messageRequiredMessage}
        />

        <Turnstile onSuccess={setToken} onError={() => setToken(null)} nonce={nonce} />

        <Button type="submit" aria-disabled={isPending || !token}>
          {sendButton}
        </Button>
      </Form>
    </section>
  );
}
