import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { SanityImage } from '@/_components/Global/SanityImage/sanity-image';
import { Collage as Props } from '@/_lib/types';

export function Collage({ images, content }: Props) {
  return (
    <div data-testid="collage" className="not-prose max-w-344 2xl:-ml-[25%] 2xl:w-[200%]">
      <figure className="max-w-344">
        <div className="relative z-1 flex items-center justify-center py-5 before:absolute before:inset-0 before:z-[-1] before:ml-[15%] before:w-[70%] before:border before:border-solid sm:py-9 sm:before:ml-[25%] sm:before:w-[50%] sm:before:border-8 lg:py-13">
          <div className="mr-3 flex w-1/2 items-center sm:mr-7 lg:mr-11">
            <div className="h-full w-full">
              {images?.[0] && (
                <SanityImage alt="" image={images[0]} className="block h-full w-full" />
              )}
            </div>
          </div>
          <div className="flex w-1/2 flex-col items-end justify-center">
            {images?.slice(1).map((image, i) => (
              <div key={i} className="mb-3 h-full w-full sm:mb-7 lg:mb-11">
                <SanityImage alt="" image={image} className="block h-full w-full" />
              </div>
            ))}
          </div>
        </div>

        {content && content.length > 0 && (
          <figcaption className="mt-2 ml-[15%] w-[70%] text-xs sm:mt-4 sm:ml-[25%] sm:w-[50%] sm:text-base">
            <RichTextClient value={content} />
          </figcaption>
        )}
      </figure>
    </div>
  );
}
