import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { Quotation as Props } from '@/_lib/types';

export async function Quotation(props: Props) {
  return (
    <div data-testid="quotation" className="not-prose">
      <blockquote>
        <RichTextClient className="text-center text-7xl font-bold" value={props.content} />

        <cite className="block text-center text-base font-semibold not-italic">
          {props.name} &middot; {props.title}
        </cite>
      </blockquote>
    </div>
  );
}
