import { DetailedHTMLProps, ElementType, HTMLAttributes, createElement } from 'react';

import classNames from 'classnames';

const PROSE_SIZES = {
  sm: 'prose prose-sm',
  md: 'prose md:prose-lg lg:prose-xl',
  lg: 'prose prose-lg md:prose-xl lg:prose-2xl',
} as const;

type ProseProps<T extends ElementType = 'div'> = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'size' | 'as'
> & {
  as?: T;
  invert?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

export const Prose = <T extends ElementType = 'div'>({
  as = 'div' as T,
  className,
  invert = false,
  size = 'md',
  ...props
}: ProseProps<T>) => {
  return createElement(as, {
    className: classNames(
      PROSE_SIZES[size],
      { 'prose-invert': invert },
      'prose-a:hover:text-red-800',
      className,
    ),
    ...props,
  });
};
