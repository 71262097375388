import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { Testimonial as Props } from '@/_lib/types';

export async function Testimonial(props: Props) {
  return (
    <blockquote data-testid="testimonial">
      <RichTextClient value={props.content} />

      <cite>
        {props.name}
        {props.title && <>&middot; {props.title}</>}
        {props.company && <>&middot; {props.company}</>}
      </cite>
    </blockquote>
  );
}
