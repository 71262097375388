'use client';

import { useEffect, useState } from 'react';

import Script from 'next/script';

// Add type definition for the Shopify Buy SDK
declare global {
  interface Window {
    ShopifyBuy: any;
  }
}

type ShopifyBuyButtonProps = {
  heading?: string;
  shopifyDomain: string;
  storefrontAccessToken: string;
  productId: string;
  buttonText: string;
  showPrice: boolean;
  showQuantity: boolean;
  showVariantSelector: boolean;
};

export default function ShopifyBuyButton({
  heading,
  shopifyDomain,
  storefrontAccessToken,
  productId,
  buttonText,
  showPrice,
  showQuantity,
  showVariantSelector,
}: ShopifyBuyButtonProps) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [buttonRendered, setButtonRendered] = useState(false);
  const containerId = `shopify-buy-button-${productId}`;

  useEffect(() => {
    if (scriptLoaded && !buttonRendered) {
      // Initialize Shopify Buy SDK
      const client = window.ShopifyBuy.buildClient({
        domain: shopifyDomain,
        storefrontAccessToken: storefrontAccessToken,
      });

      // Create UI instance
      const ui = window.ShopifyBuy.UI.init(client);

      // Render the buy button
      ui.createComponent('collection', {
        id: productId,
        node: document.getElementById(containerId),
        moneyFormat: '%24%7B%7Bamount%7D%7D', // ${amount}
        options: {
          product: {
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': 'calc(25% - 20px)',
                  'margin-left': '20px',
                  'margin-bottom': '50px',
                  width: 'calc(25% - 20px)',
                },
                img: {
                  height: 'calc(100% - 15px)',
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  top: '0',
                },
                imgWrapper: {
                  'padding-top': 'calc(75% + 15px)',
                  position: 'relative',
                  height: '0',
                },
              },
              title: {
                'font-family': 'Arial, sans-serif',
                'font-weight': 'normal',
              },
              button: {
                ':hover': {
                  'background-color': '#a71930',
                  'border-color': '#a71930',
                  color: '#ffffff',
                },
                ':focus': {
                  'background-color': '#a71930',
                  'border-color': '#a71930',
                  color: '#ffffff',
                },
                'background-color': 'transparent',
                'border-radius': '0px',
                border: '1px solid #b0b7bc',
                color: '#293339',
                'font-size': '13px',
                'font-weight': '600',
                'line-height': '1.5',
                'padding-bottom': '8px',
                'padding-left': '16px',
                'padding-right': '16px',
                'padding-top': '9px',
                'text-transform': 'uppercase',
              },
            },
            buttonDestination: 'modal',
            contents: {
              options: false,
            },
            text: {
              button: 'View product',
            },
            // googleFonts: ['Source Sans Pro'],
          },
          productSet: {
            styles: {
              products: {
                '@media (min-width: 601px)': {
                  'margin-left': '-20px',
                },
              },
            },
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
            },
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': '100%',
                  'margin-left': '0px',
                  'margin-bottom': '0px',
                },
              },
              button: {
                ':hover': {
                  'background-color': '#a71930',
                  'border-color': '#a71930',
                  color: '#ffffff',
                },
                ':focus': {
                  'background-color': '#a71930',
                  'border-color': '#a71930',
                  color: '#ffffff',
                },
                'background-color': 'transparent',
                'border-radius': '0px',
                border: '1px solid #b0b7bc',
                color: '#293339',
                'font-size': '13px',
                'font-weight': '600',
                'line-height': '1.5',
                'padding-bottom': '8px',
                'padding-left': '16px',
                'padding-right': '16px',
                'padding-top': '9px',
                'text-transform': 'uppercase',
              },
              title: {
                'font-family': 'Arial, Helvetica Neue, sans-serif',
                'font-weight': 'bold',
                'font-size': '26px',
                color: '#000000',
              },
              price: {
                color: '#000000',
              },
              compareAt: {
                color: '#000000',
              },
              unitPrice: {
                color: '#000000',
              },
              description: {
                color: '#455560',
              },
            },
            text: {
              button: 'Add to cart',
            },
          },
          option: {},
          cart: {
            styles: {
              button: {
                ':hover': {
                  'background-color': '#96172b',
                },
                'background-color': '#a71930',
                ':focus': {
                  'background-color': '#96172b',
                },
                'border-radius': '0px',
              },
              title: {
                color: '#455560',
              },
              header: {
                color: '#455560',
              },
              lineItems: {
                color: '#455560',
              },
              subtotalText: {
                color: '#455560',
              },
              subtotal: {
                color: '#455560',
              },
              notice: {
                color: '#455560',
              },
              currency: {
                color: '#455560',
              },
              close: {
                color: '#455560',
                ':hover': {
                  color: '#455560',
                },
              },
              empty: {
                color: '#455560',
              },
              noteDescription: {
                color: '#455560',
              },
              discountText: {
                color: '#455560',
              },
              discountIcon: {
                fill: '#455560',
              },
              discountAmount: {
                color: '#455560',
              },
            },
            text: {
              total: 'Subtotal',
              button: 'Checkout',
            },
          },
          toggle: {
            styles: {
              toggle: {
                'background-color': '#a71930',
                ':hover': {
                  'background-color': '#96172b',
                },
                ':focus': {
                  'background-color': '#96172b',
                },
              },
            },
          },
          lineItem: {
            styles: {
              variantTitle: {
                color: '#455560',
              },
              title: {
                color: '#455560',
              },
              price: {
                color: '#455560',
              },
              fullPrice: {
                color: '#455560',
              },
              discount: {
                color: '#455560',
              },
              discountIcon: {
                fill: '#455560',
              },
              quantity: {
                color: '#455560',
              },
              quantityIncrement: {
                color: '#455560',
                'border-color': '#455560',
              },
              quantityDecrement: {
                color: '#455560',
                'border-color': '#455560',
              },
              quantityInput: {
                color: '#455560',
                'border-color': '#455560',
              },
            },
          },
        },
      });

      setButtonRendered(true);
    }
  }, [
    scriptLoaded,
    buttonRendered,
    shopifyDomain,
    storefrontAccessToken,
    productId,
    containerId,
    buttonText,
    showPrice,
    showQuantity,
    showVariantSelector,
  ]);

  return (
    <section>
      {heading && <h2 className="mb-4 text-2xl font-bold">{heading}</h2>}

      <Script
        src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"
        onLoad={() => setScriptLoaded(true)}
      />

      <div id={containerId}></div>
    </section>
  );
}
