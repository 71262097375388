import { ComponentPropsWithoutRef, ElementType } from 'react';

type CardGridProps<T extends ElementType> = {
  as?: T;
  className?: string;
} & ComponentPropsWithoutRef<T>;

export function CardGrid<T extends ElementType = 'ul'>({
  as,
  className,
  children,
  ...props
}: CardGridProps<T>) {
  const Component = as || 'ul';

  return (
    <Component
      className={`m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(28rem,1fr))] gap-8 p-0 ${className}`}
      {...props}
    >
      {children}
    </Component>
  );
}
