import React, { useState } from 'react';

import classNames from 'classnames';
import type {
  InputProps,
  TextAreaProps,
  TextFieldProps,
  ValidationResult,
} from 'react-aria-components';
import { FieldError, Input, Label, TextField } from 'react-aria-components';

export type FloatingFieldProps =
  | (TextFieldProps & {
      description?: string;
      errorMessage?: string | ((validation: ValidationResult) => string);
      inputProps?: TextAreaProps;
      label?: string;
      type: 'textarea';
    })
  | (TextFieldProps & {
      description?: string;
      errorMessage?: string | ((validation: ValidationResult) => string);
      inputProps?: InputProps;
      label?: string;
      type: 'email' | 'password' | 'text' | 'url' | 'tel';
    });

export const FloatingField = ({
  className,
  description,
  errorMessage,
  label,
  inputProps,
  type,
  ...props
}: FloatingFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const isFocusedOrHasValue = isFocused || hasValue;

  return (
    <TextField
      data-testid="field"
      className={`${className} relative flex flex-col`}
      type={type}
      {...props}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onChange={(v) => setHasValue(v !== '')}
    >
      {/* {type === 'textarea' && (
        <TextArea className="border px-2 py-1" placeholder={placeholder} {...inputProps} />
      )} */}
      {label && (
        <Label
          className={`absolute left-3 text-slate-900 transition-all ${
            isFocusedOrHasValue ? 'top-1 text-sm' : 'top-3.25 text-base'
          }`}
        >
          {label}
        </Label>
      )}

      {type !== 'textarea' && (
        <Input
          className={classNames(
            'rounded-lg border border-gray-200 bg-white p-3 text-base transition-all',
            {
              'pt-5 pb-1': isFocusedOrHasValue,
            },
          )}
          {...inputProps}
        />
      )}

      {/* {description && <Text slot="description">{description}</Text>} */}
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </TextField>
  );
};
