import classnames from 'classnames';
import { Button as RACButton, ButtonProps as RACButtonProps } from 'react-aria-components';

type ButtonLinkProps = RACButtonProps;

/**
 * @see https://www.figma.com/design/ytBZqtwK4tJ8iog7fRC6bN/Hab.com-redesign?node-id=1267-2050&m=dev
 */
export const Button = ({ children, className, ...props }: ButtonLinkProps) => {
  return (
    <RACButton
      data-testid="button"
      className={classnames(
        `inline-flex h-10 cursor-pointer items-center justify-center gap-2.5 rounded-3xl border border-red-800 bg-red-800 px-6 py-3 text-sm font-semibold text-white hover:border-red-900 hover:bg-red-900 active:bg-red-950`,
      )}
      {...props}
    >
      {children}
    </RACButton>
  );
};
