import { Screenshots as Props } from '@/_lib/types';

export async function Screenshots(props: Props) {
  return (
    <div data-testid="screenshots">
      <h2>Screenshots</h2>
      <pre>{JSON.stringify(props, null, '  ')}</pre>
    </div>
  );
}
