import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { Video as Props } from '@/_lib/types';

export function Video({ content, videoId }: Props) {
  return (
    <figure data-testid="video">
      <iframe
        className="aspect-video w-full"
        src={`https://www.youtube-nocookie.com/embed/${videoId}`}
      />

      {content && content.length > 0 && (
        <figcaption>
          <RichTextClient value={content} />
        </figcaption>
      )}
    </figure>
  );
}
