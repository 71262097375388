import { Prose } from '@/_components/Global/Prose/prose';
import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { Attribution as Props } from '@/_lib/types';

export async function Attribution(props: Props) {
  return (
    <div data-testid="attribution">
      <div className="bg-tangerine-50 border-tangerine-600 my-6 border-l-6 p-4 md:my-12 md:p-8">
        <Prose className="prose-h2:text-2xl prose-h3:text-xl prose-h4:text-lg [&>*:first-child]:mt-0 [&>*:last-child]:mb-0">
          <RichTextClient value={props.content} />
        </Prose>
      </div>
    </div>
  );
}
