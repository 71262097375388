'use client';

import { Tab, TabList, TabPanel, Tabs } from 'react-aria-components';

import { Card } from '@/_components/Global/Card/card';
import { ALL_EVENTS_ORDERED_BY_DATEResult, EventList as EventListProps } from '@/_lib/types';

export default function EventListClient({
  data,
}: EventListProps & { data: ALL_EVENTS_ORDERED_BY_DATEResult }) {
  return (
    <div data-testid="event-list">
      <Tabs className="flex flex-col gap-4">
        <TabList aria-label="Filter events by start date" className="flex gap-4">
          <Tab id="all">All</Tab>
          <Tab id="upcoming">Upcoming</Tab>
          <Tab id="past">Past</Tab>
        </TabList>
        <TabPanel id="all">
          <nav aria-label="Latest events">
            <ul className="m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 p-0">
              {data.map((d) => {
                return (
                  <li key={d._id}>
                    <Card
                      endDate={d.endDate}
                      href={d.slug.current}
                      image={d.rollupImage}
                      startDate={d.startDate}
                      title={d.title}
                    />
                  </li>
                );
              })}
            </ul>
          </nav>
        </TabPanel>
        <TabPanel id="upcoming">
          <nav aria-label="Latest events">
            <ul className="m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 p-0">
              {data
                .filter((d) => !d.isPast)
                .map((d) => {
                  return (
                    <li key={d._id}>
                      <Card
                        endDate={d.endDate}
                        href={d.slug.current}
                        image={d.rollupImage}
                        startDate={d.startDate}
                        title={d.title}
                      />
                    </li>
                  );
                })}
            </ul>
          </nav>
        </TabPanel>
        <TabPanel id="past">
          <nav aria-label="Latest events">
            <ul className="m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 p-0">
              {data
                .filter((d) => d.isPast)
                .map((d) => {
                  return (
                    <li key={d._id}>
                      <Card
                        endDate={d.endDate}
                        href={d.slug.current}
                        image={d.rollupImage}
                        startDate={d.startDate}
                        title={d.title}
                      />
                    </li>
                  );
                })}
            </ul>
          </nav>
        </TabPanel>
      </Tabs>
    </div>
  );
}
