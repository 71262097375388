'use client';

import { ButtonLink as ButtonLinkComponent } from '@/_components/Global/ButtonLink/button-link';
import { PAGE_QUERY_FILTER_SLUGResult } from '@/_lib/types';

export type ButtonLinkBlockProps = Extract<
  NonNullable<NonNullable<PAGE_QUERY_FILTER_SLUGResult>['body']>[number],
  { _type: 'buttonLink' }
>;

export function ButtonLinkBlock(props: ButtonLinkBlockProps) {
  const { buttonText, internal, external, buttonType = 'primary' } = props;

  // Determine the href based on whether it's an internal or external link
  const href = internal?.slug?.current || external;

  if (!href) {
    return <pre>{JSON.stringify(props, null, 2)}</pre>;
  }

  return (
    <div className="not-prose" data-testid="button-link-block">
      <div className="my-4">
        <ButtonLinkComponent href={href} type={buttonType}>
          {buttonText}
        </ButtonLinkComponent>
      </div>
    </div>
  );
}
