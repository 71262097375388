'use client';

import { useState } from 'react';

import { Input, Label, TextField } from 'react-aria-components';

import { Button } from '@/_components/Global/Button/button';
import { Card } from '@/_components/Global/Card/card';
import { CardGrid } from '@/_components/Global/CardGrid/card-grid';
import { ALL_GUIDES_ORDERED_BY_DATEResult } from '@/_lib/types';

type ArticlesListClientProps = {
  data: ALL_GUIDES_ORDERED_BY_DATEResult;
};

export default function ArticlesListClient({ data }: ArticlesListClientProps) {
  const [search, setSearch] = useState('');
  const [visibleCount, setVisibleCount] = useState(24);

  if (!data) {
    return null;
  }

  const filteredData = data.filter(
    (d) =>
      !search ||
      d.title.toLowerCase().includes(search) ||
      d.tags?.map((t) => t.name.toLowerCase()).includes(search),
  );

  const visibleData = filteredData.slice(0, visibleCount);

  return (
    <section data-testid="articles-list" className="flex flex-col gap-8">
      <TextField
        autoComplete="name"
        className="flex items-center gap-2"
        name="name"
        type="text"
        isRequired
      >
        <Label>Search:</Label>
        <Input
          className="rounded-sm border"
          onChange={(e) => {
            const value = e.target.value.trim().toLowerCase();
            setSearch(value);
            window.location.hash = value;
          }}
          value={search}
          type="search"
        />
      </TextField>

      {visibleData.length === 0 && <p>No articles match your search query.</p>}

      <nav aria-label="Latest articles">
        <CardGrid>
          {visibleData.map((d) => {
            return (
              <li key={d._id}>
                <Card
                  date={d.datePublished}
                  href={d.slug.current}
                  image={d.rollupImage}
                  title={d.title}
                />
              </li>
            );
          })}
        </CardGrid>
      </nav>

      {visibleCount < filteredData.length && (
        <Button onPress={() => setVisibleCount(visibleCount + 24)}>Load more</Button>
      )}
    </section>
  );
}
