'use client';

import { useEffect, useRef, useState } from 'react';

import { throttle } from '@/_lib/throttle';

type InsightStickyHeaderProps = {
  backToTopButton?: string;
  title: string;
};

export function InsightStickyHeader({ backToTopButton, title }: InsightStickyHeaderProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const articleRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    // Find the author section at the bottom of the page
    const article = document.querySelector('article');

    if (article) {
      articleRef.current = article as HTMLDivElement;
    }

    const updateScroll = () => {
      // Show header after scrolling down 300px
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      // Calculate scroll progress
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      // Calculate progress percentage
      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      setProgress(scrollPercentage);

      // Hide header when reaching the author section
      if (articleRef.current) {
        const authorRect = articleRef.current.getBoundingClientRect();

        // Hide the header when approximately half of the article is out of view
        // Calculate when article is roughly half out of view (when bottom is at half of viewport height)
        if (authorRect.bottom <= window.innerHeight / 2) {
          setIsVisible(false);
        }
      }
    };

    // Apply throttle to the scroll handler (16ms is roughly 60fps)
    const throttledHandleScroll = throttle(updateScroll, 16);

    window.addEventListener('scroll', throttledHandleScroll);

    // Initial calculation
    updateScroll();

    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      data-testid="insights-sticky-header"
      ref={headerRef}
      className={`fixed top-0 right-0 left-0 z-50 bg-white shadow-md transition-transform duration-300 ${
        isVisible ? 'translate-y-0' : '-translate-y-full'
      }`}
    >
      <div className="relative">
        {/* Progress bar */}
        <div
          className="absolute bottom-0 left-0 h-1 w-full origin-left bg-red-800 transition-all duration-50"
          style={{ transform: `scaleX(${progress / 100})` }}
        />

        {/* Header content */}
        <div className="wrapper mx-auto flex items-center gap-10 px-4 py-4">
          {backToTopButton && (
            <button
              onClick={scrollToTop}
              className="flex cursor-pointer items-center gap-2 text-sm font-medium uppercase transition-colors hover:text-red-800"
            >
              <span>{backToTopButton}</span>

              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 18L16 12L10 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}

          <h2 className="mb-0! truncate text-lg font-bold uppercase">{title}</h2>
        </div>
      </div>
    </div>
  );
}
