import React from 'react';

import type {
  InputProps,
  TextAreaProps,
  TextFieldProps,
  ValidationResult,
} from 'react-aria-components';
import { FieldError, Input, Label, Text, TextArea, TextField } from 'react-aria-components';

export type FieldProps =
  | (TextFieldProps & {
      description?: string;
      errorMessage?: string | ((validation: ValidationResult) => string);
      inputProps?: TextAreaProps;
      label?: string;
      placeholder?: string;
      type: 'textarea';
    })
  | (TextFieldProps & {
      description?: string;
      errorMessage?: string | ((validation: ValidationResult) => string);
      inputProps?: InputProps;
      label?: string;
      placeholder?: string;
      type: 'email' | 'password' | 'text' | 'url' | 'tel';
    });

export const Field = ({
  className,
  description,
  errorMessage,
  label,
  placeholder,
  inputProps,
  type,
  ...props
}: FieldProps) => {
  return (
    <TextField data-testid="field" className={`${className} flex flex-col`} type={type} {...props}>
      {label && <Label>{label}</Label>}
      {type === 'textarea' && (
        <TextArea className="border px-2 py-1" placeholder={placeholder} {...inputProps} />
      )}

      {type !== 'textarea' && (
        <Input className="border px-2 py-1" placeholder={placeholder} {...inputProps} />
      )}

      {description && <Text slot="description">{description}</Text>}
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </TextField>
  );
};
