'use client';

import { useActionState } from 'react';

import { useSearchParams } from 'next/navigation';

import { Form } from 'react-aria-components';

import { Button } from '@/_components/Global/Button/button';
import { ButtonLink } from '@/_components/Global/ButtonLink/button-link';
import { Field } from '@/_components/Global/Field/field';
import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { GatedContent as Props } from '@/_lib/types';
import { sendGatedContent } from '@/_services/send-gated-content';

export type GatedContentState =
  | {
      status: 'Initial' | 'Invalid' | 'Failure' | 'Previously submitted';
    }
  | {
      firstName: string;
      email: string;
      status: 'Successful';
    };

export function GatedContentClient(props: Props) {
  const searchParams = useSearchParams();

  const [state, submitAction, isPending] = useActionState<GatedContentState, FormData>(
    async (state, payload) => {
      const response = await sendGatedContent(payload, props);

      if (response.status === 'Successful') {
        return {
          firstName: response.firstName,
          email: response.email,
          status: 'Successful',
        };
      }

      return response;
    },
    searchParams.has('key') ? { status: 'Previously submitted' } : { status: 'Initial' },
  );

  const processText = (blocks: any[], tokens: Record<string, string>) => {
    return blocks.map((block) => ({
      ...block,
      children: block.children.map((child: any) => ({
        ...child,
        text: child.text.replace(
          /\{(\w+)\}/g,
          (_: any, token: string) => tokens[token] || `{${token}}`,
        ),
      })),
    }));
  };

  return (
    <>
      <div hidden={state.status !== 'Initial'}>
        <h2>{props.formHeading}</h2>
        <p>{props.formSubheading}</p>

        <Form className="flex flex-col gap-4" action={submitAction}>
          <div className="flex w-full gap-4">
            <Field
              autoComplete="given-name"
              name="firstName"
              type="text"
              isRequired
              className="w-full"
              label="First name"
              errorMessage="Please enter your first name"
            />

            <Field
              autoComplete="family-name"
              name="lastName"
              type="text"
              className="w-full"
              label="Last name"
            />
          </div>

          <div className="flex w-full gap-4">
            <Field
              autoComplete="email"
              name="email"
              type="email"
              isRequired
              className="w-full"
              label="Email"
              errorMessage="Please enter your email address"
            />

            <Field
              autoComplete="company"
              name="company"
              type="text"
              isRequired
              className="w-full"
              label="Company"
              errorMessage="Please enter your company name"
            />
          </div>

          <Button type="submit" aria-disabled={isPending}>
            Submit
          </Button>
        </Form>
      </div>

      {state.status === 'Previously submitted' && (
        <>
          <RichTextClient value={props.anonymousSuccessMessage} />

          <RichTextClient value={props.bodyText} />

          {props.downloadLink && (
            <ButtonLink href={props.downloadLink} download>
              {props.afterSubmitButton}
            </ButtonLink>
          )}
        </>
      )}

      {state.status === 'Successful' && (
        <>
          <RichTextClient
            value={processText(props.successMessage ?? [], {
              firstName: state.firstName,
              email: state.email,
            })}
          />

          <RichTextClient value={props.bodyText} />

          {props.downloadLink && (
            <ButtonLink href={props.downloadLink} download>
              {props.afterSubmitButton}
            </ButtonLink>
          )}
        </>
      )}
    </>
  );
}
