import dynamic from 'next/dynamic';

import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { CodeFigure as Props } from '@/_lib/types';

// Dynamically import the syntax highlighter component
const DynamicCodeHighlighter = dynamic(() => import('./code-highlighter.client'), {
  loading: () => <div className="h-32 w-full animate-pulse rounded-md bg-slate-900"></div>,
});

export async function CodeFigure({ caption, code }: Props) {
  if (!code?.code) {
    return null;
  }

  const language = code.language || 'javascript';
  const filename = code.filename;
  const highlightedLines = code.highlightedLines || [];

  return (
    <figure data-testid="code-figure" className="not-prose my-6 flex flex-col gap-2">
      <DynamicCodeHighlighter
        code={code.code}
        language={language}
        filename={filename}
        highlightedLines={highlightedLines}
      />

      {caption && (
        <figcaption className="text-sm text-slate-600">
          <RichTextClient value={caption} />
        </figcaption>
      )}
    </figure>
  );
}
