import { CaseStudyFeature as Props } from '@/_lib/types';

export async function CaseStudyFeature(props: Props) {
  return (
    <div data-testid="case-study-feature" className="not-prose">
      <h2>Case study feature</h2>
      <pre>{JSON.stringify(props, null, '  ')}</pre>
    </div>
  );
}
