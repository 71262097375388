import { Podcast as Props } from '@/_lib/types';

export async function Podcast(props: Props) {
  return (
    <div data-testid="podcast">
      <h2>Podcast</h2>
      <pre>{JSON.stringify(props, null, '  ')}</pre>
    </div>
  );
}
