'use client';

import { useEffect, useState } from 'react';

import { Input, Label, TextField } from 'react-aria-components';

import { Card } from '@/_components/Global/Card/card';
import { CardGrid } from '@/_components/Global/CardGrid/card-grid';
import { ALL_PEOPLE_QUERYResult } from '@/_lib/types';

type TeamDirectoryClientProps = {
  data: ALL_PEOPLE_QUERYResult;
};

export default function TeamDirectoryClient({ data }: TeamDirectoryClientProps) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(document.location.hash.replace('#', ''));
  }, []);

  if (!data) {
    return null;
  }

  const filteredData = data
    .filter((d) => d.slug.current)
    .filter(
      (d) =>
        !search ||
        d.title.toLowerCase().includes(search) ||
        d.email.toLowerCase().includes(search) ||
        d.jobTitle.toLowerCase().includes(search) ||
        d.location.toLowerCase().includes(search),
    );

  return (
    <section data-testid="team-directory" className="flex flex-col gap-8">
      <TextField
        autoComplete="name"
        className="flex items-center gap-2"
        name="name"
        type="text"
        isRequired
      >
        <Label>Search:</Label>
        <Input
          className="rounded-sm border"
          onChange={(e) => {
            const value = e.target.value.trim().toLowerCase();
            setSearch(value);
            window.location.hash = value;
          }}
          value={search}
          type="search"
        />
      </TextField>

      {filteredData.length === 0 && <p>No team members match your search query.</p>}

      {filteredData.length > 0 && (
        <nav aria-label="Team members">
          <CardGrid>
            {filteredData.map((d) => {
              return (
                <li key={d._id}>
                  <Card href={d.slug.current} image={d.rollupImage} title={d.title} />
                </li>
              );
            })}
          </CardGrid>
        </nav>
      )}
    </section>
  );
}
