'use client';

import { useState } from 'react';

import { Input, Label, TextField } from 'react-aria-components';

import { Button } from '@/_components/Global/Button/button';
import { Card } from '@/_components/Global/Card/card';
import { CardGrid } from '@/_components/Global/CardGrid/card-grid';
import { ALL_WEBINARS_ORDERED_BY_DATEResult } from '@/_lib/types';

type WebinarListClientProps = {
  data: ALL_WEBINARS_ORDERED_BY_DATEResult;
};

export default function WebinarListClient({ data }: WebinarListClientProps) {
  const [search, setSearch] = useState('');
  const [visibleUpcomingCount, setVisibleUpcomingCount] = useState(12);
  const [visiblePastCount, setVisiblePastCount] = useState(12);

  if (!data) {
    return null;
  }

  const now = new Date();

  const filteredData = data.filter(
    (d) =>
      !search ||
      d.title.toLowerCase().includes(search.toLowerCase()) ||
      d.tags?.map((t) => t.name.toLowerCase()).includes(search.toLowerCase()),
  );

  const upcomingWebinars = filteredData
    .filter((d) => d.datePublished && new Date(d.datePublished) >= now)
    .sort((a, b) => {
      if (!a.datePublished || !b.datePublished) return 0;
      return new Date(a.datePublished).getTime() - new Date(b.datePublished).getTime();
    });

  const pastWebinars = filteredData
    .filter((d) => d.datePublished && new Date(d.datePublished) < now)
    .sort((a, b) => {
      if (!a.datePublished || !b.datePublished) return 0;
      return new Date(b.datePublished).getTime() - new Date(a.datePublished).getTime();
    });

  const visibleUpcomingWebinars = upcomingWebinars.slice(0, visibleUpcomingCount);
  const visiblePastWebinars = pastWebinars.slice(0, visiblePastCount);

  return (
    <section data-testid="webinar-list" className="flex flex-col gap-8">
      <TextField
        autoComplete="name"
        className="flex items-center gap-2"
        name="name"
        type="text"
        isRequired
      >
        <Label>Search:</Label>
        <Input
          className="rounded-sm border"
          onChange={(e) => {
            const value = e.target.value.trim().toLowerCase();
            setSearch(value);
            window.location.hash = value;
          }}
          value={search}
          type="search"
        />
      </TextField>

      {filteredData.length === 0 && <p>No webinars match your search query.</p>}

      {upcomingWebinars.length > 0 && (
        <>
          <h2 className="text-2xl font-bold">Upcoming Webinars</h2>
          <nav aria-label="Upcoming webinars">
            <CardGrid>
              {visibleUpcomingWebinars.map((d) => {
                return (
                  <li key={d._id}>
                    <Card
                      date={d.datePublished}
                      href={d.slug.current}
                      image={d.rollupImage}
                      title={d.title}
                    />
                  </li>
                );
              })}
            </CardGrid>
          </nav>
          {visibleUpcomingCount < upcomingWebinars.length && (
            <Button onPress={() => setVisibleUpcomingCount(visibleUpcomingCount + 12)}>
              Load more upcoming webinars
            </Button>
          )}
        </>
      )}

      {pastWebinars.length > 0 && (
        <>
          <h2 className="mt-8 text-2xl font-bold">Past Webinars</h2>
          <nav aria-label="Past webinars">
            <CardGrid>
              {visiblePastWebinars.map((d) => {
                return (
                  <li key={d._id}>
                    <Card
                      date={d.datePublished}
                      href={d.slug.current}
                      image={d.rollupImage}
                      title={d.title}
                    />
                  </li>
                );
              })}
            </CardGrid>
          </nav>
          {visiblePastCount < pastWebinars.length && (
            <Button onPress={() => setVisiblePastCount(visiblePastCount + 12)}>
              Load more past webinars
            </Button>
          )}
        </>
      )}
    </section>
  );
}
