'use client';

import { useEffect, useState } from 'react';

import { common, createStarryNight } from '@wooorm/starry-night';
import '@wooorm/starry-night/style/core';
import '@wooorm/starry-night/style/dark';
import { toJsxRuntime } from 'hast-util-to-jsx-runtime';
import { Fragment as ReactFragment, jsx, jsxs } from 'react/jsx-runtime';

type CodeHighlighterProps = {
  code: string;
  language: string;
  filename?: string;
  highlightedLines?: number[];
};

export default function CodeHighlighter({
  code,
  language,
  filename,
  highlightedLines = [],
}: CodeHighlighterProps) {
  const [codeJsx, setCodeJsx] = useState<React.ReactNode | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function highlight() {
      try {
        setIsLoading(true);

        // Create a starry night instance with common languages
        const starryNight = await createStarryNight(common);

        // Map common language names to starry-night scopes
        const languageMap: Record<string, string> = {
          js: 'source.js',
          javascript: 'source.js',
          jsx: 'source.js.jsx',
          ts: 'source.ts',
          typescript: 'source.ts',
          tsx: 'source.tsx',
          html: 'text.html.basic',
          css: 'source.css',
          json: 'source.json',
          md: 'text.md',
          markdown: 'text.md',
          // Add more mappings as needed
        };

        // Get the grammar for the specified language
        const scope =
          languageMap[language.toLowerCase()] || starryNight.flagToScope(language) || 'source.js';

        // Highlight the code
        const tree = starryNight.highlight(code, scope);

        // Convert hast to JSX
        const jsxElement = toJsxRuntime(tree, {
          Fragment: ReactFragment,
          jsx,
          jsxs,
          elementAttributeNameCase: 'react',
        });

        setCodeJsx(jsxElement);
      } catch (error) {
        console.error('Error highlighting code:', error);
        setCodeJsx(null);
      } finally {
        setIsLoading(false);
      }
    }

    highlight();
  }, [code, language]);

  // Split code into lines for line highlighting
  const codeLines = code.split('\n');

  return (
    <>
      {filename && (
        <div className="rounded-t-md bg-slate-900 px-4 py-2 font-mono text-sm text-white">
          {filename}
        </div>
      )}

      <div className="not-prose">
        {isLoading ? (
          <div className="h-32 w-full animate-pulse rounded-md bg-slate-900"></div>
        ) : (
          <pre className="rounded-md bg-slate-900 p-4 text-white">
            <code className="font-mono text-sm">{codeJsx}</code>
          </pre>
        )}
      </div>
    </>
  );
}
