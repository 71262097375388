import { Prose } from '@/_components/Global/Prose/prose';
import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { ProTip as Props } from '@/_lib/types';

export async function ProTip(props: Props) {
  return (
    <div className="not-prose" data-testid="protip">
      <div className="round relative mt-8 mb-8 rounded-2xl bg-slate-900 px-10 py-8 text-white lg:-mx-10">
        <h2 className="text-xl font-bold uppercase">🔥 {props.heading}</h2>

        <Prose className="mt-2" invert={true} size="md">
          <RichTextClient className="first:mt-0 last:mb-0" value={props.content} />
        </Prose>
      </div>
    </div>
  );
}
