import Link from 'next/link';

import classnames from 'classnames';

type ButtonLinkProps = React.ComponentProps<typeof Link> & {
  type?: 'primary' | 'secondary';
};

/**
 * @see https://www.figma.com/design/ytBZqtwK4tJ8iog7fRC6bN/Hab.com-redesign?node-id=1267-2050&m=dev
 */
export const ButtonLink = ({
  children,
  className,
  type = 'primary',
  ...props
}: ButtonLinkProps) => {
  return (
    <div className="not-prose">
      <Link
        data-testid="button-link"
        className={classnames(
          `inline-flex h-10 items-center justify-center gap-2.5 rounded-3xl border px-6 py-3 no-underline`,
          {
            'border-red-800 bg-red-800 text-white hover:border-red-900 hover:bg-red-900 hover:text-white active:bg-red-950':
              type === 'primary',
            'border-slate-900 bg-white text-slate-900 hover:border-slate-950 hover:bg-slate-50 hover:text-slate-950 active:bg-slate-50':
              type === 'secondary',
          },
          className,
        )}
        {...props}
      >
        <span className="text-sm font-semibold">{children}</span>
      </Link>
    </div>
  );
};
