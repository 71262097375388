import React from 'react';

import { PortableText, PortableTextReactComponents } from 'next-sanity';
import Link from 'next/link';

import { Attribution } from '@/_components/Blocks/Attribution/attribution';
import { Background } from '@/_components/Blocks/Background/background';
import { ButtonLinkBlock } from '@/_components/Blocks/ButtonLink/button-link-block';
import { CaseStudyFeature } from '@/_components/Blocks/CaseStudyFeature/case-study-feature';
import { CodeFigure } from '@/_components/Blocks/CodeFigure/code-figure';
import { Collage } from '@/_components/Blocks/Collage/collage';
import { Fact } from '@/_components/Blocks/Fact/fact';
import { Figure } from '@/_components/Blocks/Figure/figure';
import { GatedContent } from '@/_components/Blocks/GatedContent/gated-content';
import { HtmlEmbed } from '@/_components/Blocks/HtmlEmbed/html-embed';
import { PersonBlock, PersonBlockProps } from '@/_components/Blocks/PersonBlock/person-block';
import { PhotoGallery } from '@/_components/Blocks/PhotoGallery/photo-gallery';
import { Podcast } from '@/_components/Blocks/Podcast/podcast';
import { ProTip } from '@/_components/Blocks/ProTip/pro-tip';
import { Quotation } from '@/_components/Blocks/Quotation/quotation';
import {
  RelatedContent,
  RelatedContentProps,
} from '@/_components/Blocks/RelatedContent/related-content';
import { Screenshots } from '@/_components/Blocks/Screenshots/screenshots';
import { Testimonial } from '@/_components/Blocks/Testimonial/testimonial';
import { Video } from '@/_components/Blocks/Video/video';
import { SanityImage } from '@/_components/Global/SanityImage/sanity-image';
import {
  Attribution as AttributionProps,
  Background as BackgroundProps,
  CaseStudyFeature as CaseStudyFeatureProps,
  CodeFigure as CodeFigureProps,
  Collage as CollageProps,
  Fact as FactProps,
  Figure as FigureProps,
  GatedContent as GatedContentProps,
  HtmlEmbed as HtmlEmbedProps,
  PhotoGallery as PhotoGalleryProps,
  Podcast as PodcastProps,
  ProTip as ProTipProps,
  Quotation as QuotationProps,
  Screenshots as ScreenshotsProps,
  Testimonial as TestimonialProps,
  Video as VideoProps,
} from '@/_lib/types';

type RichTextClientProps = { className?: string; value: any };

export const RichTextClient = ({ className, value }: RichTextClientProps) => {
  if (!value) {
    return null;
  }

  const portableText = <PortableText value={value} components={getPortableTextComponents()} />;

  if (className) {
    return <div className={className}>{portableText}</div>;
  }

  return portableText;
};

export const clientOnlyComponents: Partial<PortableTextReactComponents> = {
  types: {
    attribution: ({ value }: { value: AttributionProps }) => <Attribution {...value} />,
    background: ({ value }: { value: BackgroundProps }) => <Background {...value} />,
    buttonLink: ({ value }) => <ButtonLinkBlock {...value} />,
    break: () => <hr />,
    caseStudyFeature: ({ value }: { value: CaseStudyFeatureProps }) => (
      <CaseStudyFeature {...value} />
    ),
    codeFigure: ({ value }: { value: CodeFigureProps }) => <CodeFigure {...value} />,
    collage: ({ value }: { value: CollageProps }) => <Collage {...value} />,
    fact: ({ value }: { value: FactProps }) => <Fact {...value} />,
    figure: ({ value }: { value: FigureProps }) => <Figure {...value} />,
    gatedContent: ({ value }: { value: GatedContentProps }) => <GatedContent {...value} />,
    htmlEmbed: ({ value }: { value: HtmlEmbedProps }) => <HtmlEmbed {...value} />,
    image: ({ value }) => <SanityImage alt={value.alt} className="h-auto w-full" image={value} />,
    personBlock: ({ value }: { value: PersonBlockProps }) => <PersonBlock {...value} />,
    photoGallery: ({ value }: { value: PhotoGalleryProps }) => <PhotoGallery {...value} />,
    podcast: ({ value }: { value: PodcastProps }) => <Podcast {...value} />,
    proTip: ({ value }: { value: ProTipProps }) => <ProTip {...value} />,
    quotation: ({ value }: { value: QuotationProps }) => <Quotation {...value} />,
    relatedContent: ({ value }: { value: RelatedContentProps }) => <RelatedContent {...value} />,
    screenshots: ({ value }: { value: ScreenshotsProps }) => <Screenshots {...value} />,
    testimonial: ({ value }: { value: TestimonialProps }) => <Testimonial {...value} />,
    video: ({ value }: { value: VideoProps }) => <Video {...value} />,
  },
  marks: {
    link: ({ children, value }) => <Link {...value}>{children}</Link>,
  },
};

export function getPortableTextComponents(): Partial<PortableTextReactComponents> {
  return clientOnlyComponents;
}
