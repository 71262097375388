import { Turnstile as ReactTurnstile, TurnstileProps } from '@marsidev/react-turnstile';

export function Turnstile({
  onSuccess,
  onError,
  nonce,
  ...props
}: Partial<TurnstileProps> & { nonce: string }) {
  return (
    <ReactTurnstile
      siteKey={process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY}
      onSuccess={onSuccess}
      onError={onError}
      options={{
        theme: 'light',
        size: 'invisible',
        retry: 'auto',
        refreshExpired: 'auto',
      }}
      scriptOptions={{
        defer: true,
        async: true,
        nonce: nonce ?? undefined,
      }}
      {...props}
    />
  );
}
