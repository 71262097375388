import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { Fact as Props } from '@/_lib/types';

export async function Fact(props: Props) {
  return (
    <div className="not-prose" data-testid="fact">
      <h2>{props.value}</h2>
      <RichTextClient value={props.content} />
    </div>
  );
}
