import { RichTextClient } from '@/_components/Global/RichText/rich-text.client';
import { SanityImage } from '@/_components/Global/SanityImage/sanity-image';
import { PhotoGallery as Props } from '@/_lib/types';

export async function PhotoGallery({ images, content }: Props) {
  if (!images?.length) return null;

  const count = images.length;

  return (
    <div data-testid="photo-gallery" className="not-prose">
      <figure className="embed embed--full embed--large">
        <div className="-mx-6 lg:-mx-62">
          <div className="mx-auto grid max-w-7xl grid-cols-12 gap-4 sm:gap-4">
            {/* First column - always present */}
            <div
              className={`col-span-12 ${
                count === 1 ? 'sm:col-span-12' : count === 2 ? 'sm:col-span-9' : 'sm:col-span-6'
              }`}
            >
              <div className="aspect-[1.6/1] h-full w-full">
                <SanityImage
                  alt=""
                  className="h-full w-full object-cover"
                  image={images[0]}
                  width={640}
                />
              </div>
            </div>

            {/* Second column - for 2 or more images */}
            {count > 1 && (
              <div className="col-span-6 sm:col-span-3">
                <div className="aspect-[0.76/1] h-full w-full">
                  <SanityImage
                    alt=""
                    className="h-full w-full object-cover"
                    image={images[1]}
                    width={304}
                  />
                </div>
              </div>
            )}

            {/* Third column - for 3 or more images */}
            {count > 2 && (
              <div className="col-span-6 sm:col-span-3">
                <div className="grid gap-4">
                  <div className="aspect-[1.65/1] h-full w-full">
                    <SanityImage
                      alt=""
                      className="h-full w-full object-cover"
                      image={images[2]}
                      width={304}
                    />
                  </div>

                  {count > 3 && (
                    <div className="aspect-[1.65/1] h-full w-full">
                      <SanityImage
                        alt=""
                        className="h-full w-full object-cover"
                        image={images[3]}
                        width={304}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {content && content.length > 0 && (
          <figcaption>
            <RichTextClient value={content} />
          </figcaption>
        )}
      </figure>
    </div>
  );
}
